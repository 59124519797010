<template>
  <component :is="comp" v-bind="[$attrs, $props]" v-on="$listeners" :class="classes">
    <slot>
      <b-spinner variant="gray" v-if="loading" small :class="{ 'tlw-mr-1': label }" />
      <Icon v-if="icon && !loading" :name="icon" :prefix="iconPrefix" :size="iconSize" />
      <span v-if="label">{{ label }}</span>
      <PremiumBadge size="5" v-if="premium" class="tlw--top-2 tlw-right-0" />
    </slot>
  </component>
</template>

<script>
import PremiumBadge from '@/components/PremiumBadge/index.vue';
import Icon from '@/components-new/Icon.vue';
const Variants = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CRITICAL: 'critical',
  PRIMARY_OUTLINE: 'primary-outline',
  CRITICAL_OUTLINE: 'critical-outline',
  LINK: 'link',
  LIGHT: 'light',
  LIGHT_OUTLINE: 'light-outline',
  SUCCESS: 'success',
});

const Sizes = Object.freeze({
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  NONE: 'none',
});

export default {
  name: 'MButton',
  inheritAttrs: false,
  components: {
    PremiumBadge,
    Icon,
  },
  props: {
    variant: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
    icon: {
      type: String,
    },
    iconSize: {
      type: String,
      default: 'sm',
    },
    iconPrefix: {
      type: String,
      default: 'fal',
    },
    label: {
      type: String,
    },
    premium: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    ring: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    comp() {
      if (this.$attrs['to']) {
        return 'router-link';
      } else if (this.$attrs['href']) {
        return 'a';
      }
      return 'button';
    },
    variantClass() {
      switch (this.variant) {
        case Variants.PRIMARY:
          return `tlw-bg-purple-500 tlw-border-purple-500 hover:tlw-border-purple-800 tlw-text-white hover:tlw-bg-purple-800 ${
            this.disabled
          }`;

        case Variants.SECONDARY:
          return `tlw-bg-gray-500 tlw-border-gray-500 hover:tlw-border-gray-600
                  tlw-text-white hover:tlw-bg-gray-600 hover:tlw-text-gray-dark
                  ${this.disabled}`;

        case Variants.CRITICAL:
          return `tlw-bg-red-500 tlw-border-red-500 hover:tlw-border-red-800 tlw-text-white hover:tlw-bg-red-800 ${
            this.disabled
          }`;

        case Variants.PRIMARY_OUTLINE:
          return `tlw-box-border tlw-bg-transparent tlw-text-purple-500 tlw-border-purple-500 hover:tlw-bg-purple-100 ${
            this.disabledOutline
          }`;

        case Variants.CRITICAL_OUTLINE:
          return `tlw-bg-transparent tlw-text-red-500 tlw-border-red-500 hover:tlw-bg-red-200 ${this.disabledOutline}`;

        case Variants.LINK:
          return 'tlw-bg-transparent tlw-border-transparent tlw-text-purple-500 tlw-underline hover:tlw-text-purple-800 hover:tlw-underline disabled:tlw-text-gray-500';

        case Variants.LIGHT:
          return 'tlw-bg-white tlw-border-white tlw-text-purple-500 hover:tlw-text-purple-800 hover:tlw-underline disabled:tlw-border-gray-500';

        case Variants.LIGHT_OUTLINE:
          return `tlw-bg-transparent tlw-text-white tlw-border-white ${this.disabledOutline}`;

        case Variants.SUCCESS:
          return `tlw-bg-green-500 tlw-border-transparent tlw-text-white hover:tlw-bg-green-300 hover:tlw-text-white ${
            this.disabled
          }`;

        default:
          return '';
      }
    },
    sizeClass() {
      switch (this.size) {
        case Sizes.SMALL:
          return 'tlw-px-3 tlw-py-1';
        case Sizes.MEDIUM:
          return 'tlw-px-4 tlw-py-2';
        case Sizes.LARGE:
          return 'tlw-text-base tlw-px-6 tlw-py-3';
        case Sizes.NONE:
          return '';
        default:
          return '';
      }
    },
    btnIconSizeClass() {
      switch (this.size) {
        case Sizes.SMALL:
          return 'tlw-px-2 tlw-py-2';
        case Sizes.MEDIUM:
          return 'tlw-text-base tlw-px-2.5 tlw-py-2.5';
        case Sizes.LARGE:
          return 'tlw-text-base tlw-px-4 tlw-py-4';
        case Sizes.NONE:
          return '';
        default:
          return '';
      }
    },
    baseClass() {
      return `tlw-relative tlw-flex tlw-items-center tlw-justify-center
              tlw-border-2
              tlw-rounded tlw-font-semibold tlw-no-underline tlw-duration-300
              hover:tlw-no-underline
              focus:tlw-outline-none ${this.ringClass}
              disabled:tlw-cursor-default`;
    },
    ringClass() {
      return this.ring ? 'ring-blue' : 'focus:tlw-ring-0 focus:outline-none';
    },
    btnIconClass() {
      return `tlw-bg-transparent tlw-border-transparent tlw-text-gray-800 ${this.disabled}`;
    },
    disabled() {
      return `disabled:tlw-bg-gray-300 disabled:tlw-text-gray-700 disabled:tlw-border-gray-300`;
    },
    disabledOutline() {
      return `disabled:tlw-border-gray-300 disabled:tlw-text-gray-500 disabled:tlw-bg-transparent`;
    },
    classes() {
      if (this.label) {
        return [this.baseClass, this.variantClass || 'primary', this.sizeClass];
      } else {
        return [this.baseClass, this.variantClass || this.btnIconClass, this.btnIconSizeClass];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ring-blue:focus {
  outline: 2px solid #3575d4;
  outline-offset: 1px;
}
</style>
