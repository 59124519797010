


































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import MButton from '@/shared/components/MButton.vue';

import { channelService } from '@/services';
import generalHelper from '@/shared/helpers/general';
import ToastHelper from '@/shared/helpers/toast';

const AuthModule = namespace('auth');

@Component({
  name: 'Messages',
  components: {
    MessagesTable: () => import('./components/MessagesTable.vue'),
    MessageCreatorModal: () => import('./components/MessageCreatorModal.vue'),
    hsLoading: () => import('@/components').then(comp => comp.hsLoading),
    MButton,
  },
})
export default class Messages extends Vue {
  @AuthModule.State loggedUser!: any;
  @AuthModule.State channels!: any;
  @AuthModule.Action getChannelsByUser!: any;

  isLoading: boolean = false;
  filters: any[] = [
    {
      type: 'Main',
      key: 'subject',
      label: this.$t('messages.views.list.index.filters.subject.label'),
      value: '',
    },
  ];
  currentPage: number = 1;
  totalNumberOfMessages: number = 0;
  perPage: number = 10;
  messages: any[] = [];

  mountMessagesList(page: number) {
    this.isLoading = true;

    this.getChannelsByUser({
      userId: this.loggedUser.id,
      params: [
        { key: 'per_page', value: this.perPage },
        { key: 'page', value: page },
        ...generalHelper.parseFiltersToParams(this.filters),
      ],
    })
      .then((res: any) => {
        this.totalNumberOfMessages = res.total_count;
        this.messages = res.channels;
      })
      .catch(() => ToastHelper.dangerMessage(this.$t('messages.views.list.index.toast.error')))
      .finally(() => (this.isLoading = false));
  }

  openMessageCreatorModal() {
    this.$bvModal.show('message-creator-modal');
  }

  async createMessage({ channel, message }: any) {
    try {
      const { id } = await channelService.create(channel);
      await channelService.createMessage({ ...message, channel_id: id });
      ToastHelper.successMessage(this.$t('messages.views.list.components.message-creator-modal.toast.success'));
      this.mountMessagesList(1);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('messages.views.list.index.toast.error'));
    }
  }

  created() {
    this.mountMessagesList(1);
    this.messages = this.channels;
  }

  @Watch('currentPage')
  getMessages(newValue: any) {
    this.mountMessagesList(newValue);
  }
}
