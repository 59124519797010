<template>
  <div
    class="icon-container"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
    }"
  >
    <font-awesome-icon
      :icon="[prefix, name]"
      :class="classes"
      :style="{
        width: '100%',
        height: '100%',
        maxWidth: `${width}px`,
        maxHeight: `${height}px`,
      }"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'Icon',
  components: { FontAwesomeIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: 'fas',
    },
    height: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number,
      default: 16,
    },
    size: {
      type: String,
      default: 'xxs',
    },
    classes: {
      type: [String, Array, Object],
      default: () => '',
    },
    computed: {
      iconSize() {
        if (this.size === 'xxs') return '14px';
        if (this.size === 'xs') return '16px';
        if (this.size === 'sm') return '22px';
        if (this.size === 'md') return '30px';

        return '38px';
      },
      iconContainerSize() {
        if (this.size === 'xxs') return '16px';
        if (this.size === 'xs') return '20px';
        if (this.size === 'sm') return '24px';
        if (this.size === 'md') return '32px';

        return '40px';
      },
    },
  },
};
</script>

<style scoped lang="scss">
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
