<template>
  <section class="page-header tlw-px-4 md:tlw-px-8 tlw-py-6 tlw-bg-white">
    <div class="page-header__container" :class="{ 'page-header__container-has-actions': hasActions }">
      <div class="page-header__container-info">
        <router-link
          :to="checkBackRoute"
          :class="{ 'page-header__container-back-text--only-mobile': backOnlyMobile }"
          class="page-header__container-back-text"
          @click.native="$emit('onBackRoute')"
        >
          <hs-icon icon="long-arrow-left" /> {{ backText }}
        </router-link>

        <slot name="badge"></slot>

        <h3 class="page-header__container-info-title">
          {{ title }}
        </h3>

        <p class="page-header__container-info-subtitle" v-if="subTitle" v-html="subTitle" />

        <slot name="details"></slot>
      </div>

      <div class="page-header__container-actions tlw-flex tlw-items-center md:tlw-justify-end" v-if="hasActions">
        <slot name="actions"></slot>
      </div>
    </div>
  </section>
</template>

<script>
import { isObject } from 'lodash';
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
    backText: {
      type: String,
      default: 'Voltar para página inicial',
    },
    backRoute: {
      type: [String, Object],
      default: 'HomePage',
    },
    backOnlyMobile: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasActions() {
      return !!this.$slots.actions;
    },
    checkBackRoute() {
      const { backRoute } = this;
      return isObject(backRoute) ? backRoute : { name: backRoute };
    },
  },
};
</script>

<style scoped lang="scss">
.page-header {
  &__container {
    display: grid;
    grid-template-columns: 1fr;

    &-back-text {
      color: $purple-dark;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;

      &--only-mobile {
        display: block;

        @media (min-width: $screen-md) {
          display: none;
        }
      }
    }

    &-info {
      &-title {
        color: $grey;
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-weight: 700;
        margin-top: 16px;
      }

      &-subtitle {
        white-space: pre-wrap;
        margin-bottom: 0;
        max-width: 880px;
        font-size: 0.875rem;
        line-height: 1.313rem;
        color: #6f6f6f;
        margin-top: 8px;
      }
    }

    &-has-actions {
      grid-template-columns: 1fr;
      grid-gap: 1rem;

      @media only screen and (min-width: $screen-bs-md) {
        grid-template-columns: 1fr auto;
        grid-gap: 2rem;
      }
    }

    &-actions {
      &:empty {
        display: none;
      }
    }
  }
}
</style>
