
























import { Tab } from '@/types/tab';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PremiumBadge from '@/components/PremiumBadge/index.vue';

@Component({
  components: {
    PremiumBadge
  },
})
export default class Tabs extends Vue {
  @Prop({ required: true }) tabs!: Tab[];
  @Prop({ required: true }) value!: string;

  get checkPremium() {
    return this.tabs.some(tab => !tab.permission);
  }

  get component() {
    return this.tabs.find(({ id }) => id === this.value)?.component;
  }

  selectTab(item: any) {
    this.$emit('selectTab', item);
    if (item.permission) {
      this.$emit('input', item.id);
    }
  }
}
