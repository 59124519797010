








import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/components/_structures/PageHeader.vue';
import Tabs from '@/components/Tabs/index.vue';
import Messages from './components/Tab/Messages/index.vue';
import Forum from './components/Tab/Forum/index.vue';
import TrackingHelper from '@/shared/helpers/tracking';
import { LocationSegmentProps, PaywallReasonSegmentProps } from '@/libs/paywall';

const SchoolModule = namespace('school');

@Component({
  name: 'MessagesList',
  components: {
    PageHeader,
    Tabs,
    HsPremiumModal: () => import('@/components/PremiumModal.vue'),
  },
})
export default class MessagesList extends Vue {
  @SchoolModule.State selectedSchool!: any;

  tab: string = 'messages';
  tabItems: any[] = [];

  constructor() {
    super();

    this.tabItems = [
      {
        id: 'messages',
        label: this.$root.$t('messages.views.tabs.messages'),
        permission: true,
        component: Messages,
      },
      /* 
      *****
      Funcionalidade removida, porem pode ser utilizada depois referencia: https://technical-solutions-herospark.atlassian.net/browse/BM-457 
      *****
      */
      // {
      //   id: 'forum',
      //   label: this.$root.$t('messages.views.tabs.forum'),
      //   permission: this.canActiveForumView,
      //   component: Forum,
      // },
    ];
  }

  get canActiveForumView() {
    return this.$root.$FCL.canActiveForumView();
  }

  requestPremiumFeature() {
    this.$router.push({ name: 'MyAccountPlans' });
    this.$bvModal.hide('premium-modal');
  }

  checkPermission(item) {
    if (!item.permission) {
      this.$bvModal.show('premium-modal');
      this.trackForumPaywall();
    }
  }

  trackForumPaywall() {
    TrackingHelper.trackPaywallDisplayed(
      LocationSegmentProps.FORUM,
      PaywallReasonSegmentProps.FORUM,
      this.selectedSchool.email,
      this.selectedSchool.id,
      this.selectedSchool.id
    );
  }
}
