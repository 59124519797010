









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class PremiumBadge extends Vue {
  @Prop({ default: 20 }) size!: number;

  get iconSize(): number {
    return this.size / 2;
  }
}
