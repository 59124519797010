<template>
  <hsLoading v-if="isLoading" class="flex-fill" />
  <section v-else class="d-flex flex-column flex-fill">
    <hsPageHeader :title="channel.subject" :back-text="$t('breadcrumb.Messages')" back-route="Messages">
      <template slot="details">
        <p class="mt-2">
          <strong>{{ $t('messages.views.subject.index.details.from') }} </strong>{{ channel.owner.first_name }}
          {{ channel.owner.last_name }}
        </p>
        <p>
          <strong>{{ $t('messages.views.subject.index.details.to') }} </strong>
          {{ getMembersByShowingOrHiding }}
          <a class="text-primary" href @click.prevent="membersExpanded = !membersExpanded">
            <u>
              {{ $t('messages.views.subject.index.details.show') }}
              <span v-if="membersExpanded">{{ $t('messages.views.subject.index.details.less') }}</span>
              <span v-else>{{ $t('messages.views.subject.index.details.more') }}</span>
            </u>
          </a>
        </p>
      </template>
    </hsPageHeader>
    <div class="d-flex align-items-end flex-column flex-fill flex-column p-2 p-md-5">
      <div class="d-flex flex-column flex-fill bg-white w-100 rounded-lg p-4">
        <MessageItem
          class="mt-3"
          :key="message.id"
          v-for="message in messages"
          :message="message"
          :reverse="message.sender.id === loggedUser.id"
        />

        <div class="mt-auto">
          <div class="mt-5">
            <b-form-textarea
              no-resize
              rows="6"
              max-rows="6"
              :placeholder="$t('messages.views.subject.index.inputs.message.placeholder')"
              v-model="text"
              :state="$v.text.$error ? false : null"
              @blur="$v.text.$touch()"
              @keyup.enter.stop="createMessage"
            />
          </div>
          <div class="d-flex flex-wrap mt-3">
            <div class="position-relative mr-3" v-for="imageUrl in imagesUrl" :key="imageUrl">
              <img height="64" width="95" class="rounded" :src="imageUrl" />
              <div class="text-right rounded-bottom img-url-options-container">
                <hs-button
                  class="p-0 mr-1"
                  icon="trash-alt"
                  variant="transparent"
                  @click="removeSelectedImage(imageUrl)"
                />
              </div>
            </div>
            <hsImageUploader height="84" width="95" :image="null" :multiple="true" @onMultipleFile="onMultipleFile">
              <div class="text-center" v-if="isUploading">
                <b-spinner small type="grow" variant="primary" />
              </div>
              <template v-else>
                <div class="font-size-xs text-center mb-1">
                  <p class="mb-2">{{ $t('messages.views.subject.index.inputs.image-input.uploader.label') }}</p>
                  <hs-icon class="font-size-lg text-primary" variant="light" icon="cloud-upload" />
                </div>
              </template>
            </hsImageUploader>
          </div>
          <div class="text-right">
            <hs-button variant="primary" @click.stop="createMessage" :disabled="$v.$invalid || isSendingMessage">
              {{ $t('messages.views.subject.index.buttons.send-message') }}
            </hs-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';
import { uploadService, channelService, memberService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'Subject',
  data() {
    return {
      channelId: this.$route.params.channelId,
      channel: {},
      messages: [],
      isLoading: false,
      isSendingMessage: false,
      membersExpanded: false,
      text: '',
      imagesUrl: [],
      isUploading: false,
    };
  },
  validations: {
    text: {
      required,
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
    getMembersByShowingOrHiding() {
      const TOTAL_MEMBER_STRING_SIZE = 50;
      const membersName = this.channel.members
        .filter(item => item.user.first_name !== 'Suporte Edools')
        .map(({ user }) => `${user.first_name} ${user.last_name ? user.last_name : ''}`)
        .join(', ');

      return !this.membersExpanded ? membersName.substring(0, TOTAL_MEMBER_STRING_SIZE) + '...' : membersName;
    },
    messageToSave() {
      return {
        text: `<p>${this.text}</p> ${this.imagesUrl.map(
          img => `<img alt="${this.text}" src="${img}" width="500" height="400" style="max-width: 100%; height: auto;">`
        )}`,
        channel_id: this.channelId,
      };
    },
    memberToUpdate() {
      const member = this.channel.members.find(member => member.user && member.user.id === this.loggedUser.id);
      return { ...member, last_view: dayjs().toJSON() };
    },
  },
  components: {
    hsPageHeader: () => import('@/components/_structures/PageHeader'),
    MessageItem: () => import('./components/MessageItem'),
    hsLoading: () => import('@/components').then(comp => comp.hsLoading),
    hsImageUploader: () => import('@/components').then(comp => comp.hsImageUploader),
  },
  methods: {
    removeSelectedImage(imageUrl) {
      this.imagesUrl = this.imagesUrl.filter(imgUrl => imgUrl !== imageUrl);
    },
    async onMultipleFile(files) {
      this.isUploading = true;
      Promise.all(
        files.map(async file => {
          const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
          const imgURL = await uploadService.upload(data.url, data.fields, file);

          return imgURL;
        })
      )
        .then(imgURL => (this.imagesUrl = [...this.imagesUrl, ...imgURL]))
        .catch(() => {
          ToastHelper.dangerMessage(this.$t('messages.views.subject.index.toast.error'));
        })
        .finally(() => (this.isUploading = false));
    },
    async createMessage() {
      this.isSendingMessage = true;
      try {
        const message = await channelService.createMessage(this.messageToSave);
        await memberService.update(this.memberToUpdate);

        this.messages.push(message);
        this.resetForm();
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('messages.views.subject.index.toast.error'));
      } finally {
        this.isSendingMessage = false;
      }
    },
    resetForm() {
      const { text } = this.$options.data.call(this);
      this.text = text;
      this.$v.$reset();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const channel = await channelService.getById(this.channelId);
      this.channel = channel;

      const { messages } = await channelService.getChannelMessages(this.channelId);
      this.messages = messages.reverse();
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('messages.views.subject.index.toast.error'));
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.img-url-options-container {
  background: $grey-10;
}
</style>
