var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-container",style:({
    width: (_vm.width + "px"),
    height: (_vm.height + "px"),
  })},[_c('font-awesome-icon',{class:_vm.classes,style:({
      width: '100%',
      height: '100%',
      maxWidth: (_vm.width + "px"),
      maxHeight: (_vm.height + "px"),
    }),attrs:{"icon":[_vm.prefix, _vm.name]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }