export const LocationSegmentProps = {
  MEMBERS_IMPORT: 'Members - Import',
  CLASS_RELEASE: 'Class - Release',
  FORUM: 'Forum',
  DOMAIN_CUSTOM: 'Domain - Custom',
};

export const PaywallReasonSegmentProps = {
  MEMBERS_IMPORT: 'import_members',
  CLASS_RELEASE: 'class_release',
  FORUM: 'forum',
  DOMAIN_CUSTOM: 'custom_domain',
};
